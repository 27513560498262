/* eslint-disable @typescript-eslint/ban-ts-comment */
import { deleteMethod, get, post, put } from './helpers';
import { Chart } from './types/chart';
import { Deal } from './types/deal';
import { Email } from './types/email';
import { Search, SearchPosition } from './types/search';
import { SignalClose } from './types/signal';
import { UserChangePassword, UserLogin, LoginSmsCode } from './types/user';
import { PageParams } from '../components/TableGrid';
import { AggregateInvestbox } from '../types/aggregateInvestbox';
import { AggregateStrategy } from '../types/aggregateStrategy';
import { AggregateUser } from '../types/aggregateUser';
import { Analytic } from '../types/analytic';
import { Banner } from '../types/banner';
import { StatusEnum } from '../types/clientDetails';
import { DelayedSignal } from '../types/delayedSignal';
import { Category, Question } from '../types/faq';
import { MarketSchedule, Weekend } from '../types/marketSchedule';
import { Position } from '../types/position';
import { RequestModerate } from '../types/requestModerate';
import { Nullable } from '../utils/types';
import { getStringQuery } from '../utils/url';

export const getStrategies = () => get('/strategies');

export const getPositions = (strategyId: string) => get(`/strategies/openpositions/${strategyId}`);

export const getSandboxPositions = (strategyId: string) => get(`/sandbox/openpositions/${strategyId}`);

export const getDeals = (params: Deal) => post(`/strategies/alltrades/${params.strategyId}`, params.data);

export const getSandboxDeals = (params: Deal) => post(`/sandbox/alltrades/${params.strategyId}`, params.data);

export const getChart = (params: Chart) => get(`/strategies/history/${params.strategyId}/${params.period}`);

export const getSandboxChart = (params: Chart) => get(`/sandbox/history/${params.strategyId}/${params.period}`);

export const getUser = () => get('/account/current');

export const login = (userData: UserLogin) => post('/account/signinstage', userData);

export const checkLoginSms = (data: LoginSmsCode) => post('/account/signin', data);

export const logout = () => get('/account/signout');

export const changePassword = (userData: UserChangePassword) => post(`/account/changepwd`, userData);

export const getPortfolio = (strategyId?: string) => get(`/strategies/portfolio/${strategyId}`);

export const getSandboxPortfolio = (strategyId?: string) => get(`/sandbox/portfolio/${strategyId}`);

export const closePosition = (params: SignalClose) => post(`/signal/close`, params);

export const search = (params: Search) => post(`/securities/search`, params);

export const getPositionInfo = (params: SearchPosition) => post(`/securities/info`, params);

export const newMarketSignal = (params: Partial<Position>) => post(`/signal/new`, params);

export const newMarketSignalSandbox = (params: Partial<Position>) => post(`/sandbox/signal/new`, params);

export const newMultipleMarketSignal = (params: Partial<Position>[]) => post(`/signal/multiple`, params);

export const newDelayedSignal = (params: Partial<DelayedSignal>) => post(`/signal/delayed`, params);

export const newDelayedSignalSandbox = (params: Partial<DelayedSignal>) => post(`/sandbox/signal/delayed`, params);

export const changeSignal = (params: Partial<Position>) => post('/signal/replaceposition', params);

export const getDelayedSignals = (params: Deal) => post(`/strategies/delayedsignals/${params.strategyId}`, params.data);

export const getSandboxDelayedSignals = (params: Deal) =>
  post(`/sandbox/delayedsignals/${params.strategyId}`, params.data);

export const closeDelayedSignal = (signalId: Nullable<string>) => get(`/signal/canceldelayed/${signalId}`);

export const getAppConfig = () => get(`/info`);

export const getServicesConfig = () => get('/info/services');

export const getMoneyStatistic = (params: PageParams) => post('/stat/strategies', params);

export const getClientsStatistic = (params: PageParams) => post('/stat/clients', params);

export const getSecurityInfo = (searchCriteria: string) => post('/admin/securities/info', searchCriteria);

export const getProblemClientsStatistic = (params: PageParams) => post('/clients/problem', params);

export const getSecurityBidAsk = (searchCriteria: string) => post('/admin/securities/fromrmds', searchCriteria);

export const getSecurityCS = (searchCriteria: string) => get(`/admin/securities/fromcs/${searchCriteria}`);

export const getSecurityMarketData = (searchCriteria: string) => post('/admin/securities/endofday', searchCriteria);

export const downloadDeals = (strategyId?: string) => get(`/strategies/download/trades/${strategyId}`);

export const downloadSandboxDeals = (strategyId?: string) => get(`/sandbox/download/trades/${strategyId}`);

export const downloadHistory = (strategyId?: string) => get(`/strategies/download/history/${strategyId}`);

export const downloadSandboxHistory = (strategyId?: string) => get(`/sandbox/download/history/${strategyId}`);

export const getStrategiesParams = () => get('/admin/strategies/summary');

export const getAnalytics = (params: PageParams) => post('/admin/analytics', params);

export const updateAnalytic = (data: Partial<Analytic>) => post('/admin/analytics/edit', data);

export const getAuthorDictionary = () => get('/users');

export const getCategoryDictionary = () => get('/admin/analytics/categories');

export const getUsersAdministration = (params: PageParams) => post('/admin/users', params);

export const getFullListStrategiesAdministration = () => get('/v2/admin/strategies');

export const getStrategiesAdministration = (params: PageParams) => post('/admin/strategies', params);

export const getInvestboxesAdministration = (params: PageParams) => post('/admin/investboxes', params);

export const getUserRoles = () => get('/admin/users/roles');

export const updateUser = (data: Partial<AggregateUser>) => post('/admin/users/edit', data);

export const removeUser = (id: number) => deleteMethod(`/admin/users/${id}`);

export const updateStrategy = (data: Partial<AggregateStrategy>) => post('/admin/strategies/edit', data);

export const updateInvestbox = (data: Partial<AggregateInvestbox>) => post('/admin/investboxes/edit', data);

export const changeStrategy = (data: { id: string; open?: boolean; active?: boolean }) =>
  post('/admin/strategies/state', data);

export const addStrategy = (data: Partial<AggregateStrategy>) => post('/admin/strategies/add', data);

export const publishStrategyToEva = (id: string) => post('/admin/strategies/publishToEva', id);

export const publishStrategyToBorr = (id: string) => post('/admin/strategies/publishToBorr', id);

export const updateStrategyInEva = (id: string) => post('/admin/strategies/updateInEva', id);

export const freezeStrategy = (data: { id: string; freeze: boolean }) => post('/admin/strategies/freeze', data);

export const rebalanceStrategy = (id: string) => post(`/admin/strategies/rebalance/${id}`);

export const sendStrategyToCs = (id: string) => post(`/admin/strategies/pushtocs/${id}`);

export const addInvestbox = (data: Partial<AggregateInvestbox>) => post('/admin/investboxes/add', data);

export const queryExternalClients = (params: PageParams) => post(`/admin/extclients/query`, params);

// *** admin tools
export const getClientPersInfo = (agreementId: string) => get(`/admin/clients/info/${agreementId}`);

export const rebalanceClient = (data: { agreementId: string }) => post('/admin/clients/rebalance', data);

export const calcRebalanceClient = (agreementId: string) => get(`/admin/clients/calc_rebalance/${agreementId}`);

export const attachClient = (data: { agreementId: string; strategy: string; status: StatusEnum }) =>
  post('/admin/clients/attach', data);

export const detachClient = (agreementId: string) => post(`/admin/clients/detach/${agreementId}`);

export const blockClient = (data: { agreementId: string; time: string }) => post('/admin/clients/block', data);

export const stopClientService = () => post('/admin/management/stopcs');

export const recalcStrategyHistory = (data: { id: string }) => post(`/admin/management/recalc_history/${data?.id}`);

export const getCsDiag = () => get('/admin/management/csdiag');

export const downloadStrategyChart = (data: { id: string }) => get(`/admin/strategies/rawhistory/${data?.id}`);

export const getFollowPrecision = (data: { id: string }) => post(`/admin/strategies/follow/${data?.id}`);

export const getClientSignal = (agreementId: string) => get(`/clients/signals/${agreementId}`);

export const getLimitsRmds = (agreementId: string) => get(`/admin/clients/rmdslimits/${agreementId}`);

export const getLimitsQrc = (agreementId: string) => get(`/admin/clients/qrclimits/${agreementId}`);

export const getCurrencyInfo = () => get('/admin/strategies/currencies');

export const getTariffInfo = (size: number) => get(`/admin/strategies/tariffs/${size}`);

export const getStrategyInfo = (id: string) => get(`/admin/strategies/${id}`);

export const getInvestboxInfo = (id: string) => get(`/admin/investboxes/${id}`);

export const getUserInfo = (size: number) => get(`/admin/strategies/users/${size}`);

export const getStrategyInfoRefs = (size: number) => get(`/admin/strategies/refs/${size}`);

export const getClientInfo = (tickers: string[]) => post('/admin/strategies/agreements', tickers);

export const getBanners = (params: PageParams) => post('/admin/banners', params);

export const updateBanners = (data: Partial<Banner>) => post('/admin/banners/edit', data);

export const split = (params: Partial<Position>) => post('/admin/strategies/split', params);

export const getUtmDictionary = () => get('/admin/banners/utms');

export const getStatisticStrategiesDictionary = () => get('/stat/registry/strategies');

export const getStatisticAuthorsDictionary = () => get('/stat/registry/authors');

export const getStatisticServicesDictionary = () => get('/stat/registry/services');

export const getStatisticProblemsDictionary = () => get('/clients/registry/problems');

export const getClientInfoDetails = (ticker: string) => post(`/clients/find`, ticker);

export const getClientInfoDetailsById = (agreementId: string) => get(`/clients/info/${agreementId}`);

export const getClientHistoryDetails = (params: { agreementId: string; sortType?: string }) =>
  get(`/clients/history/${params.agreementId}/${params.sortType || ' '}`);

export const getClientPositionsDetails = (agreementId: string) => get(`/clients/positions/${agreementId}`);

export const getClientChartDetails = (agreementId: string) => get(`/clients/follow/${agreementId}`);

export const getClientAccepts = (agreementId: Nullable<string>) => get(`/clients/accepts/${agreementId}`);

// @ts-ignore
export const getErrorsStatistic = (data) => post('/stat/errors', data);

// @ts-ignore
export const getErrorsByStrategyStatistic = (data) => post('/stat/errorsByStrategy', data);

// @ts-ignore
export const sandboxUploadFileData = (data) => post('/sandbox/uploadtrack', data);

export const sandboxDeleteTrack = (strategyId: string) => deleteMethod(`/sandbox/${strategyId}`);

export const sandboxTrackStatus = (strategyId: string) => get(`/sandbox/status/${strategyId}`);

export const sandboxRequestModerate = (params: RequestModerate) =>
  post(`/strategies/requestmoderate/${params.strategyId}/${params.action}`);

export const sandboxModerate = (params: RequestModerate) =>
  post(`/strategies/moderate/${params.strategyId}/${params.action}`);

export const toSandbox = (data: { strategyId: string }) => post(`/strategies/tosandbox/${data.strategyId}`);

export const updateHistory = () => get('/strategies/updatehistory');

// @ts-ignore
export const newPortfolioSignal = (data) => post(`/signal/construct`, data);

export const canSendSignal = (strategyId: string) => get(`/signal/canregistersignal/${strategyId}`);

// schedule api
export const getSchedule = () => get('/admin/schedule');

export const getForbiddenSecurities = () => get('/admin/schedule/forbidden');

export const setScheduleTimes = (data: MarketSchedule) => post('/admin/schedule/add', data);

export const addSchedule = (data: MarketSchedule) => post('/admin/schedule/add', data);

export const addScheduleWeekend = (data: Weekend) => post('/admin/schedule/weekend', data);

export const removeScheduleWeekend = (data: Weekend) => deleteMethod('/admin/schedule/weekend', data);

export const getClassCodes = () => get('/admin/schedule/codes');

export const removeSchedule = (id: string) => post(`/admin/schedule/remove/${id}`);

export const getAuditIir = (params: Record<string, string | null>) => get(`/audit/iirs/${getStringQuery(params)}`);

export const getAuditOrder = (params: Record<string, string | null>) => get(`/audit/orders/${getStringQuery(params)}`);

export const getFaqCategories = () => get('/admin/faq/categories');
export const addFaqCategory = (category: any) => post('/admin/faq/categories', category);
export const editFaqCategory = (category: Category) =>
  put(`/admin/faq/categories/${category.id}`, { name: category.name, friendlyUrl: category.friendlyUrl });
export const deleteFaqCategory = (id: string) => deleteMethod(`/admin/faq/categories/${id}`);

export const getFaqQuestions = () => get('/admin/faq/questions');
export const addFaqQuestion = (question: any) => post('/admin/faq/questions', question);
export const editFaqQuestion = (question: Question) => put(`/admin/faq/questions/${question.id}`, question);
export const deleteFaqQuestions = (id: string) => deleteMethod(`/admin/faq/questions/${id}`);

export const getFaqCategoryQuestions = (categoryId: string) => get(`/admin/faq/categories/${categoryId}`);
export const editFaqCategoryQuestions = (params: { categoryId: string; questionIds: string[] }) =>
  post(`/admin/faq/categories/${params.categoryId}/questions`, params.questionIds);

export const getComment = (strategyId: string, date: string) =>
  get(`/strategies/strategysignalcomment/${strategyId}/${date}`);

export const updateComment = (strategyId: string, date: string, comment: string) =>
  post(`/strategies/strategysignalcomment`, { strategyId, date, comment });

export const sendEmail = (data: Email) => post('/admin/mails/send', data);

export const getApiKey = (strategyId: string) => post(`/admin/strategies/${strategyId}/generate-api-key`);
